import {useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";

export const payoutService = {
    fetchArrears,
    setInitialArrears,
    createPaymentPlan,
    getPaymentPlanInWeeks,
    generatePayout,
    getPayoutHistory,
    getGeneratePayout,
    deleteGeneratePayout,
    confirmGeneratePayout,
    resendGeneratePayoutNotifications,
    arrearsOverview
}

function arrearsOverview(){
    return new Promise((resolve, reject) => {
        axios.get('/payouts/get-arrears-overview', useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function fetchArrears(userId){

    return new Promise((resolve, reject) => {
        axios.get('/payouts/get-arrears/' + userId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function setInitialArrears(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/set-initial-arrears', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function createPaymentPlan(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/create-payment-plan-in-weeks', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getPaymentPlanInWeeks(payload){

    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-payment-plan-in-weeks',payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}


function generatePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/generate', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getGeneratePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-generated-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function getPayoutHistory(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/get-payout-history', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function deleteGeneratePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/delete-generated-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function confirmGeneratePayout(payload){
    return new Promise((resolve, reject) => {
        axios.post('/payouts/confirm-generated-payout', payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}

function resendGeneratePayoutNotifications(payoutId){
    return new Promise((resolve, reject) => {
        axios.get('/payouts/resend-generated-payout-notifications/'+payoutId, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })
}